<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create New Call Log Type
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="callLogTypeTypeCreateForm"
        #default="{invalid}"
      >
        <b-card
          class="px-3 py-2"
        >
          <b-row>
            <b-col cols="md-5">
              <b-form-group
                label="Name*"
                label-for="h-call-log-type-name"
                label-cols-md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="h-call-log-type-name"
                    v-model="name"
                    placeholder="Name"
                    :state="(errors.length > 0 || nameValidation) ? false : null"
                    name="name"
                    @input="nameValidation == true ? nameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          header-tag="header"
          class="px-3 py-2"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Nature of Calls
            </h3>
          </template>
          <b-row>
            <b-col>
              <label class="block">Name</label>
            </b-col>
          </b-row>
          <b-row
            v-for="(field, index) in callNatures"
            :key="index"
            ref="row"
            class="mb-1"
          >
            <b-col
              cols="10"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Nature Of Call"
                vid="natureOfCall"
                rules="required"
              >
                <b-form-input
                  :id="'h-call-log-type-natureOfCall' + index"
                  v-model="field.natureOfCall"
                  placeholder="Nature Of Call"
                  :state="(errors.length > 0 || field.natureOfCallValidation) ? false : null"
                  name="natureOfCall"
                  @input="field.natureOfCallValidation == true ? field.natureOfCallValidation = false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="field.natureOfCallValidation"
                  class="text-danger"
                >
                  {{ field.natureOfCallValidationMessage }}
                </small>
              </validation-provider>
            </b-col>
            <b-col
              cols="2"
              md="8"
            >
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-secondary"
                type="button"
                class="btn-icon rounded-circle"
                @click="removeThisField(index)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="18"
                />
              </b-button>
            </b-col>
          </b-row>
          <hr class="dividerHR">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            variant="primary"
            @click="addCallNatureItem"
          >
            <span class="align-middle">Add Item</span>
          </b-button>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'appointment-call-log-types-index' }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Call Log Type</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: '',
      nameError: 'Valid type ID is required',
      nameValidation: false,
      callNatures: [
        {
          natureOfCall: '',
          natureOfCallValidation: false,
          natureOfCallValidationMessage: '',
        },
      ],

      // validation rules
      required,
    }
  },
  methods: {
    addCallNatureItem() {
      this.callNatures.push({
        natureOfCall: '',
        natureOfCallValidation: false,
        natureOfCallValidationMessage: '',
      })
    },
    removeThisField(index) {
      if (this.callNatures.length > 1) {
        this.callNatures.splice(index, 1)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'This have to have atleast one value.',
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
      }
    },
    submitForm() {
      this.$refs.callLogTypeTypeCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('name', this.name)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < this.callNatures.length; i++) {
            formData.append('callNatures[]', this.callNatures[i].natureOfCall)
          }

          this.$http.post('appointment/call-log-types/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Call Log Type Created.',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  allowOutsideClick: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      this.$router.push({ name: 'appointment-call-log-types-index' })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
</style>
